<template>
<div class="content-wrapper">
  <div class="pt-0 content" v-if="isList">
    <div class="card mt-3">
      <div class="card-header bg-white">
        <div class="row align-items-center">
          <div class="col-md-12">
            <div class="form-row align-items-center">
              <div class="col-md-10">
                <h5 class="card-title font-weight-semibold">{{ pageTitle }}</h5>
              </div>
              <div class="col-md-2 d-flex justify-content-end">
                <a href="javascript:;" @click="downloadPOCT()" class="btn btn-success btn-labeled btn-labeled-left">
                  <b><i class="icon-download"></i></b>
                  <span>Download</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-header">
        <div class="row">
          <div class="col-lg-9">
            <div class="row gx-1">
              <div class="col-md">
                <div class="row gx-1">
                  <div class="col-md-auto">
                    <div class="form-group mb-0">
                      <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right><i class="icon-spinner11"></i></button>
                    </div>
                  </div>
                  
                  <div class="col-md-auto d-flex">
                    <div class="form-group mb-0 d-flex">
                      <div class="input-group">
                        <date-range-picker ref="picker" :locale-data="datePickerConfig.locale" :autoApply="datePickerConfig.autoApply" v-model="dateRange" :ranges="datePickerConfig.ranges" @update="updateValues"
                        :opens="'right'"
                        >
                          <template v-slot:input="picker">
                            {{ picker.startDate | date }} - {{ picker.endDate | date }}
                          </template>
                        </date-range-picker>
                        <div class="input-group-append calendar-group">
                          <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <b-form-group class="mb-0">
                      <v-select placeholder=" -- Pilih Dokter -- " @input="doFill" v-model="filter.dpjp" :options="mDPJP" label="text" :reduce="v=>v.value"></v-select>
                    </b-form-group>
                  </div>
                  

                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
              <input class="form-control" v-model="filter.search" @input="doSearch" placeholder="Ketik Nama/No. RM Pasien" />
              <div class="form-control-feedback">
                <i class="icon-search4 text-indigo"></i>
              </div>

              <b-button class="ml-1 d-inline-flex align-items-center" variant="outline-success" id="resetBtn" @click="doResetData()">Reset</b-button>
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
          <thead>
            <tr>
              <th>No. Kedatangan</th>
              <th>Nama/No KTP</th>
              <th>No RM</th>
              <th>Diagnosa</th>
              <th>Nama Poli</th>
              <th>Tanggal Lahir</th>
              <th>Usia</th>
              <th width="120">Aksi</th>
            </tr>
          </thead>

          <tbody v-if="(dataList||[]).length">
            <template v-for="(v,k) in (dataList||[])">
              <tr :key="k">
                <td>
                  {{v.ar_reg_code||"-"}}
                  <span v-if="v.ap_is_old_pasien !== 'Y'" class="text-primary">PASIEN BARU</span>                  
                  <span v-else class="text-danger">
                    <br />PASIEN LAMA
                  </span>
                </td>
                <td>
                  <div class="d-flex flex-column align-items-start">
                    <a href="javascript:;" @click="toMonitoring(v.ar_id)" class="font-weight-semibold border-bottom" v-b-tooltip.hover.right title="Lihat Informasi Perawatan Pasien">{{ v.ap_fullname || "-" }}</a>
                    <small>{{ v.ap_nik || "-" }}</small>
                  </div>
                  <small class="text-secondary">Terdaftar {{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                </td>

                <td>
                  <div class="font-weight-semibold">{{ v.ap_code || "-" }}</div>
                </td>
                <td>
                  <a href="javascript:;" @click="lihatDiagnosa(v,k)" v-b-tooltip.click.html="v.diagnosa||'...'" class="font-weight-semibold border-bottom"> Lihat Diagnosa </a>
                </td>
                <td>
                  <div class="font-weight-semibold">{{ uppercaseWord(v.mpo_name)||"-" }}</div>
                </td>
                <td>
                  <div class="font-weight-semibold" v-if="v.ap_dob">{{v.ap_dob | moment("DD MMMM YYYY") }}</div>
                  <span v-else> - </span>
                </td>
                <td>
                  <div class="font-weight-semibold">
                    {{v.ap_usia_with_ket || "-"}}
                  </div>
                </td>
                <td>

                  <a href="javascript:;" v-if="moduleRole('pengawasan_gda')" class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800" v-b-tooltip.hover.left title="Pengawasan GDA" @click="getGDAData(v.ar_id)">
                    <i class="icon-file-text"></i>
                  </a>

                </td>
              </tr>
            </template>

          </tbody>
          <tbody v-if="data.data.length == 0">

            <tr>
              <td colspan="99">
                <div class="text-center">
                  <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                    <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!data.data">
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">
        <b-pagination class="mb-0" v-model="pageNo" :per-page="data.per_page" :total-rows="data.total" />
      </b-card-footer>

    </div>

    <b-modal
      size="lg"
      id="gdaData"
      title="Pengawasan GDA"
      hide-footer
    >
      <b-row>
        <b-col md="12">
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th>No.</th>
                <th>Waktu Tindakan</th>
                <th>Hasil GDA</th>
                <th>Tindak Lanjut</th>
                <th>Aksi</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="dataModal.is_loading">
                <tr>
                  <td colspan="5">
                    <p class="mb-0 text-center">Loading...</p>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr v-for="(v, k) in dataModal.dataGDA" :key="`kn-${k}`">
                  <td>{{ k+1 }}</td>
                  <td>{{ v.created_date | moment("D MMMM YYYY H:mm") }}</td>
                  <td>{{ v.gda }}</td>
                  <td>
                    <b-form-textarea v-if="!v.checked" name="keteranganGDA" id="keteranganGDA" rows="2" v-model="v.tindak_lanjut" class="form-control"></b-form-textarea>
                    <span v-else>{{v.tindak_lanjut||"-"}}</span>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="dataModal.check_gda"
                      v-if="!v.checked"
                      :value="v.id"
                    >
                      Hasil GDA sudah dibaca
                    </b-form-checkbox>

                    <span class="text-success" v-else>
                      <div class="icon-check"></div> Hasil GDA sudah dibaca
                    </span>
                  </td>

                </tr>

                <tr v-if="!(dataModal.dataGDA||[]).length">
                  <td colspan="5">
                    <p class="mb-0 text-center">Tidak ada data</p>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </b-col>

        <b-col md="12" v-if="(dataModal.check_gda||[]).length" class="mt-3">
          <div class="text-right">
            <button class="btn btn-labeled btn-labeled-left btn-primary" @click="doSubmitPOCT()">
              <b><i class="icon-paperplane"></i></b>
              <span>Verifikasi</span>
            </button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'

import Gen from '@/libs/Gen.js'

const _ = global._
const moment = require('moment')

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  extends: GlobalVue,
  components: {
    DateRangePicker
  },
  data() {
    return {
      patientData: {},
      loading: {
        patientInfo: false,
      },

      row: {},

      mrPendidikanKesehatan: [],
      mDPJP: [],
      mRanapBangsal: [],
      mrJenisDiit: [],
      mrBentukMakanan: [],
      mrPuasaSessions: [],
      mrValidation: {},
      configTime: {},

      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },

      datePickerConfig: {
        startDate: new Date(),
        endDate: new Date(),
        autoApply: true,
        ranges: {
          'Hari Ini': [new Date(), new Date()],
          '7 Hari Terakhir': [new Date(moment().subtract(7, 'days')), new Date()],
          '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
        },
        applyClass: 'btn-sm btn-primary',
        cancelClass: 'btn-sm btn-light',
        locale: {
          applyLabel: 'Terapkan',
          cancelLabel: 'Batal',
          direction: 'ltr',
          format: 'mm/dd/yyyy',
          separator: ' - ',
        }
      },
      dataModal: {
        is_loading: false,
        check_gda: [],
      },
    }
  },

  mounted() {
    if (this.isList) {
      this.doConvertDate()
      this.filter.status_ranap = 2
      this.apiGet()
    } else {
      this.getPatientInfo()
    }
  },

  methods: {
    doSubmitPOCT(){
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda Yakin akan menyimpan data ini?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(btn => {
        if(btn.isConfirmed){
          this.$swal.fire({
            title: 'Processing...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })

          Gen.apiRest(
            "/do/" + this.modulePage, {
              data: {
                type: "submit-poct",
                id_registrasi: this.dataModal.ar_id,
                check_gda: this.dataModal.check_gda,
                monitoring_type: this.dataModal.monitoring_type,
                data_gda: this.dataModal.dataGDA
              }
            },
            "POST"
          ).then(res => {
            this.$swal.close()

            this.$swal.fire({
              title: res.data.title,
              icon: "success",              
            }).then(() => {
              this.$bvModal.hide('gdaData')

              this.apiGet()
              this.apiGetResume()
            })
          }).catch(err => {
            this.$swal.close()

             if(err){
                err.statusType = err.status
                err.status = "error"
                err.title = err.response?.data?.title
                err.message = err.response?.data?.message
                err.messageError = err.message
            }

            this.doSetAlertForm(err)
          })
        }
      })
    },
    downloadPOCT(){
      this.$swal.fire({
        title: 'Processing...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            type: "download-laporan-poct",
            id_registrasi: this.$route.params.pageSlug,
            ...this.filter,
            dataList: this.dataList
          },
          is_blob: 'Y'
        },
        "POST"
      )
        .then(res => {
          const filename = `Laporan-RAJAL-POCT-${new Date().getTime()}.xlsx`
          const url = window.URL.createObjectURL(res.data)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          this.$swal.close()
        })
        .catch(()=>{
          this.$swal.close()
          // this.globalVar.apiGenError( err.response.data )
        })
    },

    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master || []).findIndex(x => x.value == value)
        if (index !== -1) {
          text = master[index]['text']
        }
      }
      return text
    },

    doResetData() {
      this.doReset()
      this.dateRange = {
        startDate: new Date(),
        endDate: new Date(),
      }
      this.doConvertDate()
    },
    updateValues(e){
      this.doConvertDate()
      this.doFill()
    },
    doRefreshData() {
      this.apiGet()
      window.scrollTo(0, 0)
    },
    doConvertDate() {
      this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
    },
    doPageOne() {
      this.filter.page = 1
    },
    doFill() {
      this.doPageOne()
      this.doFilter()
    },
    doSearch: _.debounce(function () {
      this.doPageOne()
      this.doFilter()
    }, 500),

    openDetail(data) {
      this.$bvModal.show('detailData')

      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-detail",
            id_pemberian_makan: data.aranpm_id
          }
        },
      ).then(res => {
        this.$set(this, 'dataModal', {
          ...data,
          ...res.data.data
        })

        this.$set(this, 'configTime', res.data.configTime)
      })
    },

    getGDAData(id) {
      this.$bvModal.show('gdaData')

      this.$set(this.dataModal, 'is_loading', true)
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-gda-data",
            id_registrasi: id
          }
        },
      ).then(res => {
        this.$set(this, 'dataModal', {
          dataGDA: res.data.data_gda,
          check_gda: [],
          is_loading: false,
          ar_id: id
        })
      })
    },

    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.query.idRegistrasi
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
      })
    },

    lihatDiagnosa(v,k){
      Gen.apiRest(
        "/do/Dashboard",
        { data:{type:'lihat-diagnosa', id: v.ar_id} },
        "POST"
      ).then(res=>{
        this.$set(v, 'diagnosa', res.data)
      })
    },

    doSubmit() {
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            ...this.row,
            type: 'update-pemberian-makan'
          }
        },
        "POST"
      ).then(resp => {
        this.$swal.close()
        this.$swal({
          title: resp.data.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then(result => {
          if (result.value) {
            this.apiGet()
            this.$bvModal.hide("formData")

          }
        })
      }).catch(() => {
        this.$swal.close()

        return this.$swal({
          icon: 'error',
          text: 'Terjadi Kesalahan Yang Tidak Diketahui'
        })
      })
    }
  },

  computed: {
    pendidikanKesehatanGroups() {
      return _.chunk(this.dataModal.pendidikan_kesehatan, 2)
    }
  },
  filters: {
    date(val) {
      return val ? moment(val).format("D MMM YYYY") : ""
    }
  },
  watch: {
    $route() {
      if (this.isList) {
        this.doConvertDate()

        this.apiGet()
      } else {
        this.getPatientInfo()
      }
    }
  },
}
</script>
